import { default as _91_46_46_46token_93nPDcE1lifaMeta } from "/home/igorFront/production/smscodefront/pages/authorization-social/[...token].vue?macro=true";
import { default as indexai2toriHXxMeta } from "/home/igorFront/production/smscodefront/pages/authorization/index.vue?macro=true";
import { default as indexEgnng9w8PbMeta } from "/home/igorFront/production/smscodefront/pages/cabinet/api/index.vue?macro=true";
import { default as index7PBZOGuTVoMeta } from "/home/igorFront/production/smscodefront/pages/cabinet/index.vue?macro=true";
import { default as index8t4tQPg94LMeta } from "/home/igorFront/production/smscodefront/pages/cabinet/numbers-history/index.vue?macro=true";
import { default as indexDTmKn1R4QXMeta } from "/home/igorFront/production/smscodefront/pages/cabinet/payments/index.vue?macro=true";
import { default as indexZiYIVjMfYyMeta } from "/home/igorFront/production/smscodefront/pages/cabinet/profile/index.vue?macro=true";
import { default as indexYkXHrSxYFkMeta } from "/home/igorFront/production/smscodefront/pages/forgot-password/index.vue?macro=true";
import { default as indexDGXwW29jT5Meta } from "/home/igorFront/production/smscodefront/pages/index.vue?macro=true";
import { default as index3d5UIKa2aQMeta } from "/home/igorFront/production/smscodefront/pages/payment/result/index.vue?macro=true";
import { default as indexQkxVGGoKi3Meta } from "/home/igorFront/production/smscodefront/pages/registration/index.vue?macro=true";
import { default as _91_46_46_46token_93b6AWgDhKF2Meta } from "/home/igorFront/production/smscodefront/pages/reset-password/[...token].vue?macro=true";
export default [
  {
    name: _91_46_46_46token_93nPDcE1lifaMeta?.name ?? "authorization-social-token___en___default",
    path: _91_46_46_46token_93nPDcE1lifaMeta?.path ?? "/authorization-social/:token(.*)*",
    meta: _91_46_46_46token_93nPDcE1lifaMeta || {},
    alias: _91_46_46_46token_93nPDcE1lifaMeta?.alias || [],
    redirect: _91_46_46_46token_93nPDcE1lifaMeta?.redirect,
    component: () => import("/home/igorFront/production/smscodefront/pages/authorization-social/[...token].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_93nPDcE1lifaMeta?.name ?? "authorization-social-token___en",
    path: _91_46_46_46token_93nPDcE1lifaMeta?.path ?? "/en/authorization-social/:token(.*)*",
    meta: _91_46_46_46token_93nPDcE1lifaMeta || {},
    alias: _91_46_46_46token_93nPDcE1lifaMeta?.alias || [],
    redirect: _91_46_46_46token_93nPDcE1lifaMeta?.redirect,
    component: () => import("/home/igorFront/production/smscodefront/pages/authorization-social/[...token].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_93nPDcE1lifaMeta?.name ?? "authorization-social-token___ru",
    path: _91_46_46_46token_93nPDcE1lifaMeta?.path ?? "/ru/authorization-social/:token(.*)*",
    meta: _91_46_46_46token_93nPDcE1lifaMeta || {},
    alias: _91_46_46_46token_93nPDcE1lifaMeta?.alias || [],
    redirect: _91_46_46_46token_93nPDcE1lifaMeta?.redirect,
    component: () => import("/home/igorFront/production/smscodefront/pages/authorization-social/[...token].vue").then(m => m.default || m)
  },
  {
    name: indexai2toriHXxMeta?.name ?? "authorization___en___default",
    path: indexai2toriHXxMeta?.path ?? "/authorization",
    meta: indexai2toriHXxMeta || {},
    alias: indexai2toriHXxMeta?.alias || [],
    redirect: indexai2toriHXxMeta?.redirect,
    component: () => import("/home/igorFront/production/smscodefront/pages/authorization/index.vue").then(m => m.default || m)
  },
  {
    name: indexai2toriHXxMeta?.name ?? "authorization___en",
    path: indexai2toriHXxMeta?.path ?? "/en/authorization",
    meta: indexai2toriHXxMeta || {},
    alias: indexai2toriHXxMeta?.alias || [],
    redirect: indexai2toriHXxMeta?.redirect,
    component: () => import("/home/igorFront/production/smscodefront/pages/authorization/index.vue").then(m => m.default || m)
  },
  {
    name: indexai2toriHXxMeta?.name ?? "authorization___ru",
    path: indexai2toriHXxMeta?.path ?? "/ru/authorization",
    meta: indexai2toriHXxMeta || {},
    alias: indexai2toriHXxMeta?.alias || [],
    redirect: indexai2toriHXxMeta?.redirect,
    component: () => import("/home/igorFront/production/smscodefront/pages/authorization/index.vue").then(m => m.default || m)
  },
  {
    name: indexEgnng9w8PbMeta?.name ?? "cabinet-api___en___default",
    path: indexEgnng9w8PbMeta?.path ?? "/cabinet/api",
    meta: indexEgnng9w8PbMeta || {},
    alias: indexEgnng9w8PbMeta?.alias || [],
    redirect: indexEgnng9w8PbMeta?.redirect,
    component: () => import("/home/igorFront/production/smscodefront/pages/cabinet/api/index.vue").then(m => m.default || m)
  },
  {
    name: indexEgnng9w8PbMeta?.name ?? "cabinet-api___en",
    path: indexEgnng9w8PbMeta?.path ?? "/en/cabinet/api",
    meta: indexEgnng9w8PbMeta || {},
    alias: indexEgnng9w8PbMeta?.alias || [],
    redirect: indexEgnng9w8PbMeta?.redirect,
    component: () => import("/home/igorFront/production/smscodefront/pages/cabinet/api/index.vue").then(m => m.default || m)
  },
  {
    name: indexEgnng9w8PbMeta?.name ?? "cabinet-api___ru",
    path: indexEgnng9w8PbMeta?.path ?? "/ru/cabinet/api",
    meta: indexEgnng9w8PbMeta || {},
    alias: indexEgnng9w8PbMeta?.alias || [],
    redirect: indexEgnng9w8PbMeta?.redirect,
    component: () => import("/home/igorFront/production/smscodefront/pages/cabinet/api/index.vue").then(m => m.default || m)
  },
  {
    name: index7PBZOGuTVoMeta?.name ?? "cabinet___en___default",
    path: index7PBZOGuTVoMeta?.path ?? "/cabinet",
    meta: index7PBZOGuTVoMeta || {},
    alias: index7PBZOGuTVoMeta?.alias || [],
    redirect: index7PBZOGuTVoMeta?.redirect,
    component: () => import("/home/igorFront/production/smscodefront/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: index7PBZOGuTVoMeta?.name ?? "cabinet___en",
    path: index7PBZOGuTVoMeta?.path ?? "/en/cabinet",
    meta: index7PBZOGuTVoMeta || {},
    alias: index7PBZOGuTVoMeta?.alias || [],
    redirect: index7PBZOGuTVoMeta?.redirect,
    component: () => import("/home/igorFront/production/smscodefront/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: index7PBZOGuTVoMeta?.name ?? "cabinet___ru",
    path: index7PBZOGuTVoMeta?.path ?? "/ru/cabinet",
    meta: index7PBZOGuTVoMeta || {},
    alias: index7PBZOGuTVoMeta?.alias || [],
    redirect: index7PBZOGuTVoMeta?.redirect,
    component: () => import("/home/igorFront/production/smscodefront/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: index8t4tQPg94LMeta?.name ?? "cabinet-numbers-history___en___default",
    path: index8t4tQPg94LMeta?.path ?? "/cabinet/numbers-history",
    meta: index8t4tQPg94LMeta || {},
    alias: index8t4tQPg94LMeta?.alias || [],
    redirect: index8t4tQPg94LMeta?.redirect,
    component: () => import("/home/igorFront/production/smscodefront/pages/cabinet/numbers-history/index.vue").then(m => m.default || m)
  },
  {
    name: index8t4tQPg94LMeta?.name ?? "cabinet-numbers-history___en",
    path: index8t4tQPg94LMeta?.path ?? "/en/cabinet/numbers-history",
    meta: index8t4tQPg94LMeta || {},
    alias: index8t4tQPg94LMeta?.alias || [],
    redirect: index8t4tQPg94LMeta?.redirect,
    component: () => import("/home/igorFront/production/smscodefront/pages/cabinet/numbers-history/index.vue").then(m => m.default || m)
  },
  {
    name: index8t4tQPg94LMeta?.name ?? "cabinet-numbers-history___ru",
    path: index8t4tQPg94LMeta?.path ?? "/ru/cabinet/numbers-history",
    meta: index8t4tQPg94LMeta || {},
    alias: index8t4tQPg94LMeta?.alias || [],
    redirect: index8t4tQPg94LMeta?.redirect,
    component: () => import("/home/igorFront/production/smscodefront/pages/cabinet/numbers-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexDTmKn1R4QXMeta?.name ?? "cabinet-payments___en___default",
    path: indexDTmKn1R4QXMeta?.path ?? "/cabinet/payments",
    meta: indexDTmKn1R4QXMeta || {},
    alias: indexDTmKn1R4QXMeta?.alias || [],
    redirect: indexDTmKn1R4QXMeta?.redirect,
    component: () => import("/home/igorFront/production/smscodefront/pages/cabinet/payments/index.vue").then(m => m.default || m)
  },
  {
    name: indexDTmKn1R4QXMeta?.name ?? "cabinet-payments___en",
    path: indexDTmKn1R4QXMeta?.path ?? "/en/cabinet/payments",
    meta: indexDTmKn1R4QXMeta || {},
    alias: indexDTmKn1R4QXMeta?.alias || [],
    redirect: indexDTmKn1R4QXMeta?.redirect,
    component: () => import("/home/igorFront/production/smscodefront/pages/cabinet/payments/index.vue").then(m => m.default || m)
  },
  {
    name: indexDTmKn1R4QXMeta?.name ?? "cabinet-payments___ru",
    path: indexDTmKn1R4QXMeta?.path ?? "/ru/cabinet/payments",
    meta: indexDTmKn1R4QXMeta || {},
    alias: indexDTmKn1R4QXMeta?.alias || [],
    redirect: indexDTmKn1R4QXMeta?.redirect,
    component: () => import("/home/igorFront/production/smscodefront/pages/cabinet/payments/index.vue").then(m => m.default || m)
  },
  {
    name: indexZiYIVjMfYyMeta?.name ?? "cabinet-profile___en___default",
    path: indexZiYIVjMfYyMeta?.path ?? "/cabinet/profile",
    meta: indexZiYIVjMfYyMeta || {},
    alias: indexZiYIVjMfYyMeta?.alias || [],
    redirect: indexZiYIVjMfYyMeta?.redirect,
    component: () => import("/home/igorFront/production/smscodefront/pages/cabinet/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexZiYIVjMfYyMeta?.name ?? "cabinet-profile___en",
    path: indexZiYIVjMfYyMeta?.path ?? "/en/cabinet/profile",
    meta: indexZiYIVjMfYyMeta || {},
    alias: indexZiYIVjMfYyMeta?.alias || [],
    redirect: indexZiYIVjMfYyMeta?.redirect,
    component: () => import("/home/igorFront/production/smscodefront/pages/cabinet/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexZiYIVjMfYyMeta?.name ?? "cabinet-profile___ru",
    path: indexZiYIVjMfYyMeta?.path ?? "/ru/cabinet/profile",
    meta: indexZiYIVjMfYyMeta || {},
    alias: indexZiYIVjMfYyMeta?.alias || [],
    redirect: indexZiYIVjMfYyMeta?.redirect,
    component: () => import("/home/igorFront/production/smscodefront/pages/cabinet/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexYkXHrSxYFkMeta?.name ?? "forgot-password___en___default",
    path: indexYkXHrSxYFkMeta?.path ?? "/forgot-password",
    meta: indexYkXHrSxYFkMeta || {},
    alias: indexYkXHrSxYFkMeta?.alias || [],
    redirect: indexYkXHrSxYFkMeta?.redirect,
    component: () => import("/home/igorFront/production/smscodefront/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexYkXHrSxYFkMeta?.name ?? "forgot-password___en",
    path: indexYkXHrSxYFkMeta?.path ?? "/en/forgot-password",
    meta: indexYkXHrSxYFkMeta || {},
    alias: indexYkXHrSxYFkMeta?.alias || [],
    redirect: indexYkXHrSxYFkMeta?.redirect,
    component: () => import("/home/igorFront/production/smscodefront/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexYkXHrSxYFkMeta?.name ?? "forgot-password___ru",
    path: indexYkXHrSxYFkMeta?.path ?? "/ru/forgot-password",
    meta: indexYkXHrSxYFkMeta || {},
    alias: indexYkXHrSxYFkMeta?.alias || [],
    redirect: indexYkXHrSxYFkMeta?.redirect,
    component: () => import("/home/igorFront/production/smscodefront/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexDGXwW29jT5Meta?.name ?? "index___en___default",
    path: indexDGXwW29jT5Meta?.path ?? "/",
    meta: indexDGXwW29jT5Meta || {},
    alias: indexDGXwW29jT5Meta?.alias || [],
    redirect: indexDGXwW29jT5Meta?.redirect,
    component: () => import("/home/igorFront/production/smscodefront/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexDGXwW29jT5Meta?.name ?? "index___en",
    path: indexDGXwW29jT5Meta?.path ?? "/en",
    meta: indexDGXwW29jT5Meta || {},
    alias: indexDGXwW29jT5Meta?.alias || [],
    redirect: indexDGXwW29jT5Meta?.redirect,
    component: () => import("/home/igorFront/production/smscodefront/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexDGXwW29jT5Meta?.name ?? "index___ru",
    path: indexDGXwW29jT5Meta?.path ?? "/ru",
    meta: indexDGXwW29jT5Meta || {},
    alias: indexDGXwW29jT5Meta?.alias || [],
    redirect: indexDGXwW29jT5Meta?.redirect,
    component: () => import("/home/igorFront/production/smscodefront/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index3d5UIKa2aQMeta?.name ?? "payment-result___en___default",
    path: index3d5UIKa2aQMeta?.path ?? "/payment/result",
    meta: index3d5UIKa2aQMeta || {},
    alias: index3d5UIKa2aQMeta?.alias || [],
    redirect: index3d5UIKa2aQMeta?.redirect,
    component: () => import("/home/igorFront/production/smscodefront/pages/payment/result/index.vue").then(m => m.default || m)
  },
  {
    name: index3d5UIKa2aQMeta?.name ?? "payment-result___en",
    path: index3d5UIKa2aQMeta?.path ?? "/en/payment/result",
    meta: index3d5UIKa2aQMeta || {},
    alias: index3d5UIKa2aQMeta?.alias || [],
    redirect: index3d5UIKa2aQMeta?.redirect,
    component: () => import("/home/igorFront/production/smscodefront/pages/payment/result/index.vue").then(m => m.default || m)
  },
  {
    name: index3d5UIKa2aQMeta?.name ?? "payment-result___ru",
    path: index3d5UIKa2aQMeta?.path ?? "/ru/payment/result",
    meta: index3d5UIKa2aQMeta || {},
    alias: index3d5UIKa2aQMeta?.alias || [],
    redirect: index3d5UIKa2aQMeta?.redirect,
    component: () => import("/home/igorFront/production/smscodefront/pages/payment/result/index.vue").then(m => m.default || m)
  },
  {
    name: indexQkxVGGoKi3Meta?.name ?? "registration___en___default",
    path: indexQkxVGGoKi3Meta?.path ?? "/registration",
    meta: indexQkxVGGoKi3Meta || {},
    alias: indexQkxVGGoKi3Meta?.alias || [],
    redirect: indexQkxVGGoKi3Meta?.redirect,
    component: () => import("/home/igorFront/production/smscodefront/pages/registration/index.vue").then(m => m.default || m)
  },
  {
    name: indexQkxVGGoKi3Meta?.name ?? "registration___en",
    path: indexQkxVGGoKi3Meta?.path ?? "/en/registration",
    meta: indexQkxVGGoKi3Meta || {},
    alias: indexQkxVGGoKi3Meta?.alias || [],
    redirect: indexQkxVGGoKi3Meta?.redirect,
    component: () => import("/home/igorFront/production/smscodefront/pages/registration/index.vue").then(m => m.default || m)
  },
  {
    name: indexQkxVGGoKi3Meta?.name ?? "registration___ru",
    path: indexQkxVGGoKi3Meta?.path ?? "/ru/registration",
    meta: indexQkxVGGoKi3Meta || {},
    alias: indexQkxVGGoKi3Meta?.alias || [],
    redirect: indexQkxVGGoKi3Meta?.redirect,
    component: () => import("/home/igorFront/production/smscodefront/pages/registration/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_93b6AWgDhKF2Meta?.name ?? "reset-password-token___en___default",
    path: _91_46_46_46token_93b6AWgDhKF2Meta?.path ?? "/reset-password/:token(.*)*",
    meta: _91_46_46_46token_93b6AWgDhKF2Meta || {},
    alias: _91_46_46_46token_93b6AWgDhKF2Meta?.alias || [],
    redirect: _91_46_46_46token_93b6AWgDhKF2Meta?.redirect,
    component: () => import("/home/igorFront/production/smscodefront/pages/reset-password/[...token].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_93b6AWgDhKF2Meta?.name ?? "reset-password-token___en",
    path: _91_46_46_46token_93b6AWgDhKF2Meta?.path ?? "/en/reset-password/:token(.*)*",
    meta: _91_46_46_46token_93b6AWgDhKF2Meta || {},
    alias: _91_46_46_46token_93b6AWgDhKF2Meta?.alias || [],
    redirect: _91_46_46_46token_93b6AWgDhKF2Meta?.redirect,
    component: () => import("/home/igorFront/production/smscodefront/pages/reset-password/[...token].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_93b6AWgDhKF2Meta?.name ?? "reset-password-token___ru",
    path: _91_46_46_46token_93b6AWgDhKF2Meta?.path ?? "/ru/reset-password/:token(.*)*",
    meta: _91_46_46_46token_93b6AWgDhKF2Meta || {},
    alias: _91_46_46_46token_93b6AWgDhKF2Meta?.alias || [],
    redirect: _91_46_46_46token_93b6AWgDhKF2Meta?.redirect,
    component: () => import("/home/igorFront/production/smscodefront/pages/reset-password/[...token].vue").then(m => m.default || m)
  }
]